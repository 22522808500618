@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Styles */
body {

  margin: 0;
  padding: 0;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-position: center center; */

  background-color: #ffffff;
  color: #000000;
  cursor: cell;
}

/* Apply background image only when activeSection is 'home' */
/* body.home {
  background-image: url('./ryan.png');
  size: 10px;
} */

/* body.vidProjects {
  /* background-image: url('./drone2.jpeg'); Replace with your actual image URL */
  /* margin: 0;
  padding: 0; */
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-position: center center; */
/* } */


.links {
  color: #000;
}


hr {
  border: none;
  border-top: 1px solid black;
  width: 80%; /* Full width */
  margin-top: 2%;
  margin-bottom: 3%;
}



a {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: left;
}

/* Header Styles */
.App-header {
  z-index: 10;
  padding: 1rem;
  border-bottom: 1px solid #000000;
}

nav {
  z-index: 10;
  display: flex;
  justify-content: space-around;
}

nav a {
  z-index: 10;
  color: #000000;
  font-weight: 450;
  transition: color 0.3s;
}

nav a:hover {
  color: #5b4d4d;
  transform: scale(1.02);
}

/* Section Styles */
.section {
  z-index: 10;
  margin: 2rem 1rem;
}

#home {
  flex-direction: column;
  display: flex;
  margin-top: 3rem;
  justify-content: center;
}

.section-title {
  z-index: 10;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  
}

.home-container-1 {
  flex-direction: row;
  display: flex;
  margin-top: -5rem;
  justify-content: center;


}

.profile-image {
  flex-basis: 600px;
  display: flex;
  width: 150px;
  height: auto;
  border-radius: 50%;
}

.home-stats {
  display: flex;
  right: 8rem;
  top: 14rem;
}

.ryanPng img {
  display: flex;
  width: 700px;
  height: auto ;
  margin-left: -5rem;
  margin-top: -5.5rem;
}


.home-name {
  display: flex;
  font-family: "Work Sans", sans-serif;
  font-size: 6rem;
  font-style: normal;
  width: 120%;
  margin-left: -8.5rem;
  margin-top: 7rem;
}

.home-name:hover {
  color: #5b4d4d;
}
.home-text {
  display: flex;
  font-family: "Brawler", serif;
  width: 100%;
  margin-left: -8rem;
  margin-top: -2rem;
  font-size: 1.18rem;
  
}


.home-hr-1 {
  border: none;
  border-top: 1px solid black;
  width: 15rem; /* Full width */
  margin-left: 9rem;
  margin-top: -.06rem;
}

.home-hr-2 {
  border: none;
  border-top: 1px solid black;
  width: 80%; /* Full width */
  
  margin-top: 2rem;
}

.home-container-2 {
  flex-direction: row;
  display: flex;
  margin-top: 0rem;
  justify-content: center;
  align-items: center;
}

.home-counter {
  border: 1px solid black;
  padding: 1rem;
}

.paragraph-1 {
  font-family: "Brawler", serif;
  width: 100%;
  margin-top: -2rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.paragraph-2 {
  font-family: "Brawler", serif;
  width: 40%;
  padding: 1rem;
  margin-left: 0rem;
}

.home-container-3 {
  flex-direction: row;
  display: flex;
  margin-top: 0rem;
  justify-content: center;
  align-items: center;
}


.drone-stack {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5rem;
}

.home-drone {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 500px;
  border-radius: 5px; 
}

.home-drone:hover {
  box-shadow: 0 0 5px #5b4d4d;
  transform: scale(1.01);
}

.admin-button button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 0;
  margin-top: 2rem;
}



/* Blog List Styles */

.blog-text-div {
  width: 100%;
  display: flex;
  justify-content: center;
}


.blog-text {
  font-family: "Brawler", serif;
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  padding: 1rem;
  border: 2px solid #fff; /* Add white border */
  border-radius: 5px; /* Add border radius */
}

.blog-list-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-list {
  margin-top: 1rem;
  width: 85%;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}


.blog-list li {
  background-color: transparent;
}

.blog-list button {
  background-color: #5b4d4d;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.3s;
}

.blog-list button:hover {
  background-color: #000000;
  transform: scale(1.05);
}

.blog-list button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.blog-content {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #5b4d4d;
  border-radius: 5px;
  color: white;
}

.animation-container {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
}

.animation {
  display: inline;
  justify-content: center;
  width: 100%;
  height: auto;
}
  

/* Projects List Styles */
.vidProjects-list-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.vidProjects-list {
  margin-top: 1rem;
  width: 85%;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.vidProjects-list li {
  background-color: transparent;
}

.vidProjects-list a {
  text-decoration: underline;
}

.vidProjects-list a:hover {
  color: #ffffff;
}


.video-button {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
  max-width: 320px; /* Ensure button does not exceed image width */
  
  padding: 10px;
  background-color:#5b4d4d;
  border: 2px solid #fff; /* Add white border */
  border-radius: 10px; /* Add border radius */
}

.video-title {
  max-width: 85%;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure text does not wrap */

}

.video-button:hover {
  background-color: black;
  transform: scale(1.05);
}

.video-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}



.cropped-thumbnail {
  width: 240px; /* Increase width */
  height: 135px; /* Increase height to maintain 16:9 aspect ratio */
  overflow: hidden;
  position: relative;
}

.cropped-thumbnail img {
  position: absolute;
  top: -15%; /* Adjust to move the image up and center */
  left: 0;
  width: 100%; /* Scale the width to cover the container */
  height: 130%; /* Scale the height to cover the container */
  object-fit: cover; /* Ensures the image covers the container without distortion */
}



.video-content h3, .video-content p, .video-content iframe {
  margin-left: 13rem;
  color: #fff;
  width: 70%;
  display: flex;
  justify-content: center;
  
}

.video-content h3 {
  padding-top: 3rem;
  font-size: 1.5rem;
}
.video-content p {
  padding-bottom: 2rem;
}

.video-content iframe {
  padding-bottom: 5rem;
}

.video-content {
  width: 100%;
  
  background-color: #474646;
  border-radius: 5px;
}

/* Contact Form Styles */
.devProjects-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.devProjects-form label {
  font-weight: 500;
  text-align: left;
}

.devProjects-form input, .devProjects-form textarea {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.devProjects-form button {
  background-color: #000;
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s;
}














.lifesMotto {
  width: 40%;
  font-style: italic;
  color: #5b4d4d;
  display: flex;
  justify-content: center;
}
 

.gallery-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gallery-list {
  margin-top: 1rem;
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.gallery-list li {
  background-color: transparent;
}

.gallery-list a {
  text-decoration: underline;
}

.gallery-list a:hover {
  color: #ffffff;
}


.gallery-button {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
  
  
  background-color: white !important;
  border: 0px solid #fff; /* Add white border */
  border-radius: 10px; /* Add border radius */
}

.gallery-title {
  max-width: 100%;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure text does not wrap */
  color: #fff;

}

.gallery-button:hover {
  background-color: black;
  transform: scale(1.05);
}

.gallery-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}



.gallery-thumbnail {
  width: 320px; /* Increase width */
  height: 180px; /* Increase height to maintain 16:9 aspect ratio */
  overflow: hidden;
  position: relative;
}

.gallery-thumbnail img {
  position: absolute;
  top: -15%; /* Adjust to move the image up and center */
  left: 0;
  width: 100%; /* Scale the width to cover the container */
  height: 130%; /* Scale the height to cover the container */
  object-fit: cover; /* Ensures the image covers the container without distortion */
}



.gallery-content {
  display: flex;
  flex-direction: column; /* Arrange children in a column (vertical) */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text horizontally */
}

.gallery-content p {
  font-family: "Brawler", serif;
  margin: 0; /* Remove default margin */
  margin-bottom: 1rem;
  padding: 0.5em; /* Optional: Adjust padding for spacing */
}

.gallery-content img {
  max-width: 100%; /* Ensure image is responsive */
  width: 80%;
  height: auto; /* Maintain aspect ratio */
}



